function getStatus(msg){
   var codigo;
    onScan.attachTo(document, {
        suffixKeyCodes: [3,25], // enter-key expected at the end of a scan
        reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
        onScan: function(sCode, iQty) { // Alternative to document.addEventListener('scan')
            console.log('Scanned: ' + iQty + 'x ' + sCode); 
            codigo = sCode;
            //var s= document.getElementById('oculto');
            //s.value = codigo

            var element = document.getElementById('oculto');
            element.value = codigo;
            var event = new Event('change');
            element.dispatchEvent(event);
        }
    });
    return codigo;
}